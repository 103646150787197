<template>
  <div class="animated-div-container">
    <div v-for="(content, index) in contents" :key="index" class="animated-div"
      :class="{ active: activeIndex === index }">
      <h1>{{ content[0] }}</h1>
      <div class="line-hero"></div>
      <p>{{ content[1] }}</p>
    </div>
  </div>
  <a class="hero-btn" href="#About" data-aos="fade-up" data-aos-duration="1500">Mehr erfahren</a>
</template>

<script>

export default {
  name: 'AnimatedDivs',
  data() {
    return {
      contents: [
        ['DIGITALISIERUNG', 'Durch Digitalisierung die Produktivität steigern, Kosten reduzieren und Kundenerfahrung sowie Kundenzufriedenheit verbessern.'],
        ['KI', 'KI hat das Potenzial unser Leben in vielen Bereichen zu verbessern, wenn wir sie verantwortungsvoll einsetzen.'],
        ['DATEN', 'Dank Daten neue Erkenntnisse gewinnen, komplexe Probleme lösen und innovative Lösungen entwickeln.'],
        ['AUTOMATISIERUNG', 'Aufgaben schneller, genauer und fehlerfreier erledigen und damit die Produktivität, Effizienz und Qualität steigern.'],
        ['WISSEN', '"Wissen ist Macht" und hilft uns dabei Entscheidungen zu treffen, Probleme zu lösen und unser Leben zu verbessern.']
      ],
      activeIndex: 0,
    };
  },
  methods: {
    switchToNext() {
      this.activeIndex = (this.activeIndex + 1) % this.contents.length;
    },
  },
  mounted() {
    this.interval = setInterval(this.switchToNext, 7000); // Switch every 7 seconds
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.animated-div-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 10rem;
  z-index: 2;
}

.hero-btn {
    background-color: var(--persian-orange);
    color: var(--white);
    padding: 0.75rem;
    font-size: 0.75rem;
    text-decoration: none;
    z-index: 3;
}

.hero-btn:hover {
    background-color: var(--celestial-blue);
}

.animated-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.animated-div>h1 {
  font-size: 2rem;
  letter-spacing: 0.25rem;
  color: var(--persian-orange);
}

.animated-div>p {
  font-family: 'Crimson Text';
  color: var(--persian-orange);
  font-weight: 300;
}

.animated-div.active {
  opacity: 1;
}

/*#region Media queries*/

/* Notebook */
@media (max-width: 1550px) {}

/* Tablet */
@media (max-width: 991px) {
  .animated-div>h1 {
  font-size: 1.5rem;
  letter-spacing: 0.25rem;
}
}

/* Mobile */
@media (max-width: 767px) {

}

/*#endregion*/
</style>