<template>
  <div class="employee-list">
    <employee-card v-for="employee in employees" :key="employee.id" :employee="employee" />
  </div>
</template>
  
<script>
import EmployeeCard from './EmployeeCard.vue';

export default {
  name: 'EmployeeComponent',
  components: {
    EmployeeCard
  },
  data() {
    return {
      employees: [
        {
          id: 1,
          imageUrl: require('../assets/images/employees/Markus.jpg'),
          fade: '1000',
          name: 'Markus Pullmann',
          role: 'Team Lead & Digitalisierung',
          description: 'Markus ist unser erfahrener Stratege und agiler Team-Lead, der die Interessen unserer Kunden mit deren Stakeholdern im Blick hält. Seit seiner Schulzeit berät und implementiert er praxisnahe, innovative Lösungen für Unternehmen aller Größen, von kleinen Startups bis hin zu großen Konzernen und Bundesbehörden. Er ist ein Experte darin auch komplexe Geschäftsanforderungen in maßgeschneiderte und leicht bedienbare Lösungen zu übersetzen, die sich nahtlos in die spezifischen Anforderungen und Umgebungen seiner Kunden integrieren.'
        },
        {
          id: 2,
          imageUrl: require('../assets/images/employees/Maren.jpg'),
          fade: '1500',
          name: 'Maren Classen',
          role: 'Data Science & Business Intelligence',
          description: 'Maren ist unsere Datenanalystin mit Schwerpunkt Business Intelligence. Sie unterstützt unsere Kunden, indem sie ihre Daten, analysiert, visualisiert und daraus umsetzbare Erkenntnisse generiert. Dadurch schafft sie tiefgehende Business Insights die der Entscheidungsfindung dienen.  Sie beherrscht die technischen Aspekte der Datenanalyse, behält dabei die wirtschaftlichen Anforderungen ihrer Kunden im Blick und entwickelt so sowohl effiziente als auch geschäftlich sinnvolle Lösungsansätze.'
        },
        {
          id: 3,
          imageUrl: require('../assets/images/employees/Jenny.jpg'),
          fade: '1750',
          name: 'Jenny Nguyen',
          role: 'Prozess- & Qualitätsmanagement',
          description: 'Jenny verfügt über fundierte Kenntnisse in der Qualitätssicherung und überprüft Software und Daten systematisch entsprechend der definierten Anforderungen. Um komplexe Geschäftsabläufe zu optimieren zerlegt sie diese in Teilschritte, initiiert Verbesserungen und erhöht den Automatisierungsgrad. Ihre Erfahrungen mit Robotics Process Automization (RPA) setzt sie zur Verbesserungen von Prozessen, die Altsysteme oder Systeme ohne Schnittstellen tangieren, ein.'
        },
        {
          id: 4,
          imageUrl: require('../assets/images/employees/Alex.jpg'),
          fade: '2000',
          name: 'Alexander Basse',
          role: 'IT Security & KI',
          description: 'Alex ist Softwareentwickler mit einem starken Fokus auf die Entwicklung von qualitativ hochwertiger und benutzerfreundlicher Software. In seinem Informatik Studium erlangte er tiefes theoretisches Grundlagenwissen in den Bereichen IT Security und Machinellem Lernen. Dieses konnte er bereits im Rahmen seiner jahrenlangen Praxiserfahrung einsetzen. Er arbeitet als Softwareentwickler an Lösungen für Unternehmen aus den Branchen Logistik, Consulting und Finanzen.'
        }
      ]
    };
  }
};
</script>
<style scoped>
.employee-list {
  display: flex;
  justify-content: space-between;
  gap: 5px 10px;
}

/*#region Media queries*/

/* Notebook */
@media (max-width: 1550px) {}

/* Tablet */
@media (max-width: 991px) {
  .employee-list {
    /* flex-direction: ; */
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Mobile */
@media (max-width: 767px) {
  .employee-list {
    gap: 1rem;
  }
}

/*#endregion*/
</style>
  