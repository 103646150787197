<template>
    <form v-if="!isSent" @submit.prevent="submitForm" data-aos="fade-up" data-aos-duration="3000">
        <input type="text" id="name" placeholder="Name" v-model="formData.name" required>
        <input type="email" id="email" placeholder="Email" v-model="formData.email" required>
        <textarea id="message" placeholder="Nachricht..." v-model="formData.message" required></textarea>
        <div class="g-recaptcha" data-sitekey="6LcFTLQUAAAAADGmGVO8fE-FQtih15beGEzD9DJQ" data-callback="onRecaptchaVerified"></div>
        <button type="submit" :disabled="isButtonDisabled">Nachricht senden</button>
    </form>
    <div class="sent-success" v-if="isSent">
        <font-awesome-icon :icon="['fas', 'circle-check']" class="icon" />
        <h2>Erfolg!</h2>
        <p>Ihre Nachricht wurde versendet.</p>
    </div>
</template>
  
<script>
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);

const axiosInstance = axios.create({
    baseURL: '',
});

export default {
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            formData: {
                name: '',
                email: '',
                message: ''
            },
            isSent: false,
            isButtonDisabled: true
        };
    },
    methods: {
        async submitForm() {
            try {
                const response = await axiosInstance.post('/backend/contact.php', this.formData);
                console.log('Form submitted successfully', response.data);

                this.formData = {
                    name: '',
                    email: '',
                    message: ''
                };

                if (response.status == 200)
                    this.isSent = true;

            } catch (error) {
                console.error('Error submitting form', error);
            }
        },
        onRecaptchaVerified() {
            this.isButtonDisabled = false;
        },
    },
    mounted: function() {
        window.onRecaptchaVerified = this.onRecaptchaVerified
    }
}
</script>
<style scoped>
form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
    padding: 3rem 0;
    margin: auto;
}

input,
textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--white);
    font-size: 0.6125rem;
    padding: 0.5rem 0;
    color: var(--white);
}

input::placeholder,
textarea::placeholder {
    color: var(--white);
}

input,
textarea:focus-visible {
    outline: none;
}

textarea {
    resize: vertical;
    min-height: 5rem;
}

button {
    background-color: var(--davys-gray);
    border: none;
    border-radius: 3px;
    color: var(--white);
    padding: 0.5rem;
    font-size: 0.6125rem;
    transition: background-color 0.5s ease;
    text-transform: uppercase;
}

button:hover {
    cursor: pointer;
    background-color: var(--persian-orange);
}

button.disabled,
button:disabled,
button[disabled] {
    background-color: var(--davys-gray);
    color: var(--white);
    cursor: not-allowed;
    opacity: 0.3;
}

.sent-success {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
    padding: 3rem 0;
    margin: auto;
}

.sent-success h2,
.sent-success p {
    color: var(--white);
}

.sent-success h2 {
    font-size: 2rem;
    letter-spacing: 1.5px;
}

.sent-success p {
    font-size: 1rem;
    letter-spacing: 2px;
    font-weight: 300;
}

.sent-success .icon {
    color: var(--white);
    font-size: 4rem;
}

.g-recaptcha{
    margin: auto;
}

/*#region Media queries*/

/* Notebook */
@media (max-width: 1550px) {}

/* Tablet */
@media (max-width: 991px) {}

/* Mobile */
@media (max-width: 767px) {
    form {
        width: 90%;
    }

    input,
    textarea {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid var(--white);
        font-size: 1rem;
        padding: 1rem 0;
    }

    textarea {
        min-height: 10rem;
    }

    button {
        font-size: 1rem;
    }
}

/*#endregion*/
</style> 