<template>
    <section data-section="Home" id="Home" class="section section-first">
        <vue-particles color="#9DCAE7" linesColor="#9DCAE7" :hoverEffect="true" :clickEffect="false" :particleOpacity="0.5"
            :lineOpacity="0.2" :particlesNumber="75" :moveSpeed="1" :linesDistance="150"></vue-particles>
        <div class="background"></div>
        <AnimatedDivs />
    </section>
    <section data-section="About" id="About" class="section section-about">
        <div>
            <h3 data-aos="fade-in" data-aos-duration="2500">Wissenschaftliche Expertise trifft auf kreative Lösungsfindung
            </h3>
            <h2 data-aos="fade-in" data-aos-duration="2000">Willkommen bei scientelligence</h2>
            <div data-aos="zoom-in" data-aos-duration="3000" class="line-section"></div>
            <p data-aos="fade-up" data-aos-duration="1000">Unsere Mission ist es, die neuesten Entwicklungen im Bereich der
                Künstlichen Intelligenz in reale Anwendungen
                zu verwandeln, die einen echten Mehrwert für Ihre Mitarbeitenden und Ihre Unternehmen darstellen. Bei
                scientelligence wissen wir, dass jedes Unternehmen einzigartige Prozesse und Daten hat. Deshalb bieten wir
                maßgeschneiderte Lösungen für Ihre individuellen Anforderungen an. Sie erhalten wertvolle Einblicke,
                verbessern Ihre Datenqualität und Prozesse, treffen fundierte Entscheidungen und lernen Neues über Ihr
                Unternehmen.</p>
        </div>
    </section>
    <div class="section-cite">
        <p class="cite" data-aos="fade-right" data-aos-duration="1500">"Ich interessiere mich nicht für Daten um ihrer
            selbst willen, sondern dafür, wie sie genutzt werden
            können, um
            Dinge zu verbessern."</p>
        <p data-aos="fade-left" data-aos-duration="1500">Bill Gates</p>
    </div>
    <section data-section="Services" id="Services" class="section section-services">
        <div>
            <h3 data-aos="fade-in" data-aos-duration="2500">Die drei Säulen von scientelligence</h3>
            <h2 data-aos="fade-in" data-aos-duration="2000">Unsere Leistungen</h2>
            <div data-aos="zoom-in" data-aos-duration="3000" class="line-section"></div>
            <p data-aos="fade-up" data-aos-duration="1000">Daten und Informationen sind der Treibstoff für den Erfolg in der
                digitalen Welt. Durch den Einsatz von
                Künstlicher Intelligenz und Machinellen Lernens unterstützen wir Sie bei Automatisierung und Digitalisierung
                damit Sie diesen Treibstoff effizient nutzen können. Hierbei setzen wir insbesondere auf die folgenden drei
                Säulen auf:</p>
            <TabbedContent data-aos="fade-up" data-aos-duration="2000" />
        </div>
    </section>

    <div class="section-tech">
        <div>
            <img v-for="(iconIndex, i) in icons" :key="i" :src="icons[i]" alt="Icon" class="icon" />
        </div>
        <div>
            <img v-for="(iconIndex, i) in icons" :key="i" :src="icons[i]" alt="Icon" class="icon" />
        </div>
    </div>

    <section data-section="Team" id="Team" class="section section-team">
        <div>
            <h3 data-aos="fade-in" data-aos-duration="2500">Innovativ, vielseitig und engagiert</h3>
            <h2 data-aos="fade-in" data-aos-duration="2000">Unser Team</h2>
            <div data-aos="zoom-in" data-aos-duration="3000" class="line-section"></div>
            <p data-aos="fade-up" data-aos-duration="1000">Mit unserer gebündelten Expertise in den Bereichen Künstliche
                Intelligenz, Data Science und Entscheidungsunterstützung stehen wir Ihnen zur Seite, um Ihre Projekte
                erfolgreich umzusetzen.</p>
            <Employees />
        </div>
    </section>
    <section data-section="Contact" id="Contact" class="section section-contact">
        <div>
            <h3 data-aos="fade-in" data-aos-duration="2500">Bereit für die Zukunft?</h3>
            <h2 data-aos="fade-in" data-aos-duration="1000">Kontaktieren Sie uns!</h2>
            <div data-aos="zoom-in" data-aos-duration="3000" class="line-section"></div>
            <p data-aos="fade-up" data-aos-duration="1000">Wir stehen bereit gemeinsam mit Ihnen Ihre datengetriebene Transformation zu einem Erfolg zu machen und die Zukunft Ihres Unternehmens zu gestalten.</p>
            <ContactForm data-aos="fade-up" data-aos-duration="1000" />
        </div>
    </section>
</template>
<script>
import AnimatedDivs from './AnimatedDivs';
import ContactForm from './ContactForm';
import Employees from './Employees';
import TabbedContent from './TabbedContent';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);

export default {
    name: 'MainComponent',
    components: {
        AnimatedDivs,
        ContactForm,
        Employees,
        TabbedContent
    },
    data() {
        return {
            icons: [
                require("@/assets/svg/Csharp.svg"),
                require("@/assets/svg/Python.svg"),
                require("@/assets/svg/Docker.svg"),
                require("@/assets/svg/OpenCV.svg"),
                require("@/assets/svg/Tensorflow.svg"),
                require("@/assets/svg/PostGIS.svg"),
                require("@/assets/svg/Pytorch.svg")
            ]
        }
    }
}
</script>
<style scoped>
.section {
    min-height: 100vh;
    display: flex;
    padding: 0 20%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}

.section:not(.section-first) {
    z-index: 2;
}

.section h2 {
    font-size: 2rem;
    padding-bottom: 0.5rem;
    letter-spacing: 1.5px;
}

.section h3 {
    font-size: 1rem;
    font-family: 'Crimson Text';
    font-weight: 300;
    padding-bottom: 1rem;
    letter-spacing: 1.5px;
}

.section p {
    color: var(--davys-gray);
    padding: 2rem 0;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 2px;
    font-weight: 300;
}

section>div:first-child {
    padding: 6rem 0;
    width: 100%;
}

.section-first {
    background-size: 150% auto;
    background-repeat: no-repeat;
    background-position: right center;
    background-attachment: fixed;
    position: relative;
}

.section-first .background {
    background-image: url('../assets/images/backgrounds/hand_ai_1.jpg');
    background-size: cover;
    background-size: 150% auto;
    background-repeat: no-repeat;
    background-position: right center;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;
}

.section-first .background::after {
    content: '';
    height: 100%;
    width: 100%;
    display: block;
    background-color: var(--davys-gray-transparent);
}

.section-first #particles-js {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100vh;
    padding: 0;
}

.section-about {
    background-color: var(--davys-gray);
    color: var(--davys-gray);
}

.section-about h2,
.section-about h3 {
    color: var(--baby-blue);
}

.section-about p {
    color: var(--white)
}

.section-about .line-section {
    background-color: var(--persian-orange);
}

.section-services {
    background-color: var(--white);
    color: var(--white);
}

.section-services h2,
.section-services h3 {
    color: var(--persian-orange);
}

.section-services p {
    color: var(--davys-gray);
}

.section-services .line-section {
    background-color: var(--baby-blue);
}

.section-team {
    background-color: var(--davys-gray);
    color: var(--davys-gray);
}

.section-team h2,
.section-team h3 {
    color: var(--peach);
}

.section-team p {
    color: var(--white);
}

.section-team .line-section {
    background-color: var(--baby-blue);
}

.section-contact {
    background-color: var(--celestial-blue);
    color: var(--celestial-blue);
}

.section-contact p,
.section-contact h2,
.section-contact h3 {
    color: var(--white);
}

.section-contact .line-section {
    background-color: var(--white);
}

.section-cite {
    position: relative;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 1rem;
    min-height: 10vh;
    background-image: url('../assets/images/backgrounds/hand_ai_2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    background-attachment: fixed;
}

.section-cite::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--celestial-blue-transparent)
}

.section-cite p {
    color: var(--white-transparent);
    font-weight: bold;
    font-size: 1rem;
}

.section-cite .cite {
    font-family: 'Crimson Text';
    font-weight: 500;
}

.section-tech {
    display: flex;
    background-color: var(--celestial-blue);
    overflow: hidden;
}

.section-tech div {
    flex: none;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    display: flex;
    animation: logoloop 40s linear infinite;
    padding: 1rem 0;
}

.section-tech img {
    width: 100%;
    max-height: 50px;
    margin: 0 4rem;
    filter: drop-shadow(0px 0px 0px var(--white));
}

@keyframes logoloop {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

/*#region Media queries*/

/* Notebook */
@media (max-width: 1550px) {
    .section {
        padding: 0 10%;
    }
}

/* Tablet */
@media (max-width: 991px) {
    .section {
        padding: 5%;
    }

    .section-first .background {
        background-size: cover;
        background-size: 200% auto;
        background-repeat: no-repeat;
        background-position: 75% center;
        background-attachment: fixed;
    }

    .section-cite {
        background-size: 200% auto;
        background-position: center 40%;
    }

    .section-tech img {
        margin: 0 2rem;
    }
}

/* Mobile */
@media (max-width: 767px) {
    .section {
        padding: 5%;
    }

    .section-first .background {
        background-size: 400% auto;
        background-repeat: no-repeat;
        background-position: 60% 0;
        background-attachment: fixed;
        background-position: 50% 10%;
    }

    .section-cite {
        background-size: 400% auto;
        background-position: 70% 0%;
    }

    .section h2 {
        font-size: 1.5rem;
    }

    .section p {
        font-size: 0.75rem;
        line-height: 1rem;
    }
}

/*#endregion*/
</style>