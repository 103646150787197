<template>
  <header class="nav">
    <div class="nav-content">
      <div class="logo">
        <a href="#Home">
          <img src="@/assets/svg/logo.svg" alt="" srcset="" width="36px">
          <p><span>scien</span>telligence</p>
        </a>
      </div>
      <nav class="nav-links">
        <a href="#Home" data-link="Home">Home</a>
        <a href="#About" data-link="About">Über uns</a>
        <a href="#Services" data-link="Services">Leistungen</a>
        <a href="#Team" data-link="Team">Team</a>
        <a href="#Contact" data-link="Contact">Kontakt</a>
      </nav>
      <nav class="nav-links-mobile" :class="{ active: isMenuActive }">
        <a href="#Home" data-link="Home" @click="closeMenu">Home</a>
        <a href="#About" data-link="About" @click="closeMenu">Über uns</a>
        <a href="#Services" data-link="Services" @click="closeMenu">Leistungen</a>
        <a href="#Team" data-link="Team" @click="closeMenu">Team</a>
        <a href="#Contact" data-link="Contact" @click="closeMenu">Kontakt</a>
      </nav>
      <a class="burger-button" @click="toggleMenu">
        <font-awesome-icon :icon="['fas', 'bars']" class="icon" />
      </a>
    </div>
    <div class="nav-progress-container">
      <div class="nav-progress-bar" :style="{ width: progressBarWidth }"></div>
    </div>
  </header>
</template>
  
<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
  name: 'HeaderComponent',
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      isMenuActive: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    closeMenu() {
      this.isMenuActive = false;
    }
  },
  setup() {
    const progressBarWidth = ref('0%');

    const calculateProgress = () => {
      const winScroll = window.scrollY;
      const height = document.documentElement.scrollHeight - window.innerHeight;
      const scrolled = (winScroll / height) * 100;
      progressBarWidth.value = scrolled + '%';
    };

    const observedSections = ref([]);
    const observer = ref(null);

    const callback = (entries) => {
      entries.forEach((entry) => {
        const sectionId = entry.target.getAttribute('data-section');
        const link = document.querySelector(`[data-link="${sectionId}"]`);

        if (entry.isIntersecting) {
          link.classList.add('active');
        } else {
          link.classList.remove('active');
        }
      });
    };

    onMounted(() => {
      window.addEventListener('scroll', calculateProgress);

      const sections = document.querySelectorAll('[data-section]');
      sections.forEach((section) => {
        observedSections.value.push(section);
      });

      observer.value = new IntersectionObserver(callback, { threshold: 0.5 });
      observedSections.value.forEach((section) => {
        observer.value.observe(section);
      });
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', calculateProgress);

      observer.value.disconnect();
    });

    return { progressBarWidth };
  }
}
</script>
  
<style scoped>
.nav {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.nav-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 2rem;
  background-color: var(--white);
  align-items: center;
  padding: 1.5rem 8rem;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo a {
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-decoration: none;
  align-items: center;
}

.logo p,
.logo span {
  color: var(--davys-gray);
  font-size: 1.125rem;
  font-weight: 500;
  user-select: none;
}

.logo span {
  animation: change 20s infinite;
}

@keyframes change {
  0% {
    color: var(--persian-orange);
  }

  50% {
    color: var(--celestial-blue);
  }

  100% {
    color: var(--persian-orange);
  }
}

.logo>img {
  vertical-align: middle;
}

.burger-button {
  display: none;
}

.icon {
  font-size: 1.5rem;
  color: var(--davys-gray);
}

.nav-links {
  display: flex;
  padding: 1rem;
  scroll-behavior: smooth;
}

.nav-links>a {
  text-decoration: none;
  color: var(--davys-gray);
  margin: 0.75rem;
  font-size: 0.6125rem;
  letter-spacing: 0, 09375rem;
  text-transform: uppercase;
}

.nav-links>a:hover,
.nav-links>a.active {
  color: var(--persian-orange);
}

.nav-links-mobile {
  position: absolute;
  top: 3rem;
  right: 0;
  display: none;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);
  border-top: 1px solid var(--davys-gray);
  border-bottom: 1px solid var(--davys-gray);
  padding: 1rem 0;
}

.nav-links-mobile>a {
  text-decoration: none;
  color: var(--davys-gray);
  margin: 0.8125rem;
  font-size: 0.825rem;
  letter-spacing: 0, 09375rem;
  text-transform: uppercase;
  text-align: center;
}

.nav-links-mobile.active {
  display: flex;
  max-height: fit-content;
}

.nav-progress-container {
  width: 100%;
  height: 3px;
  background: transparent;
}

.nav-progress-bar {
  height: 3px;
  background: var(--celestial-blue);
  width: 0%;
}

/*#region Media queries*/

/* Notebook */
@media (max-width: 1550px) {}

/* Tablet */
@media (max-width: 991px) {

  .nav-content {
    padding: 1.5rem 1rem;
  }

  .nav-links {
    padding: 0 0.5rem;
  }

  .logo p {
    display: none;
  }
}

/* Mobile */
@media (max-width: 767px) {
  .burger-button {
    display: block;
  }

  .nav-links {
    display: none;
  }
}

/*#endregion*/
</style>