<template>
    <div class="tabbed-content">
        <div class="tabs">
            <div v-for="(tab, index) in tabs" :key="index" @click="activeTab = index"
                :class="{ active: activeTab === index }" class="tab">
                <font-awesome-icon :icon="tab.icon" class="tab-icon" />
                <div :class="{ active: activeTab === index }" class="marker"></div>
            </div>
        </div>
        <div class="tab-content">
            <div v-for="(tab, index) in tabs" :key="index" v-show="activeTab === index" class="tab-pane">
                <h2>{{ tab.title }}</h2>
                <p>{{ tab.content }}</p>
            </div>
        </div>
    </div>
</template>
  
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
    data() {
        return {
            activeTab: 0,
            tabs: [
                {
                    icon: ['fas', 'list-check'],
                    title: 'Daten- und Qualitätsmanagement',
                    content: 'Eine hohe Datenqualität ist entscheidend, denn fehlerhafte Daten können u.a. zu mangelhaften Entscheidungen, Verschlechterung der Kundenzufriedenheit, Rechtsstreitigkeiten, Reputationsverlust und damit insbesondere zu wirtschaftlichem Schaden führen. \n \n Wir etablieren mit Ihnen gemeinsam ein Datenqualitätsmanagementsystems. Damit überführen wir Ihre Datenbestände in definierte Datenstandards mit spezifischen Qualitätsanforderungen und führen die Validierung sowie die Bereinigung der Daten aus. Ebenso unterstützen wir Sie bei der Verbesserung des Datenaustauschs zwischen Softwaresystemen und mit Ihren Partnern.'
                },
                {
                    icon: ['fas', 'microchip'],
                    title: 'Datengewinnung und -verarbeitung',
                    content: 'Trotz aller Bemühungen treffen leider heute noch nicht alle Dokumente in Ihrem Unternehmen digital ein. Dieser Umstand verursacht häufig eine manuelle, personalintensive und fehleranfällige Weiterverarbeitung. \n \n Durch den Einsatz von KI-gestützten Methoden automatisieren wir gemeinsam die Weiterverarbeitung dieser Dokumente unabhängig davon ob es sich um Papierdokumente, E-Mails, Textdateien oder PDFs handelt. Zusätzlich digitalisieren und integrieren wir heute häufig noch nicht automatisch verwertbare Daten, wie Scans, Bilder, Videos, Audio-Gesprächsaufzeichungen oder Sensordaten in Ihre Datenbestände und Prozesse. Durch Anbindung bisher nicht systematisch genutzter Quellen, wie Daten von Geschäftspartnern, öffentlichen Registern, kommerziellen Datenanbietern oder Open Data Plattformen reichern wir die Daten weiter an.'
                },
                {
                    icon: ['fas', 'magnifying-glass-chart'],
                    title: 'Analyse, Informationsgewinnung und Entscheidungsunterstützung',
                    content: 'Um fundierte unternehmerische Entscheidungen treffen zu können etablieren wir gemeinsam Werkzeuge zur Datenanalyse und Informationsgewinnung. Neben den für die jeweilige Datenanalyse (deskriptiv, inferenziell, prädiktiv und preskriptiv) klassischen Werkzeugen setzen wir hier auch auf Verfahren des Maschinellen Lernens wie beispielsweise Neuronale Netze. \n \n Wir definieren und pflegen hochgradig anpassbare und informative Dashboards für verschiedene Bereiche und Management-/Detailebenen um Ihre Unternehmung im Großen und Kleinen abzubilden und transparent zu machen. \n \n Erst durch das systematische Sammeln von hochqualitativen Daten und den daraus gewonnen Informationen sind langfristig fundierte Entscheidungen möglich.'
                }
            ]
        };
    },
    components: {
        FontAwesomeIcon
    }
};
</script>
  
<style scoped>
.tabbed-content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.tabs {
    display: flex;
    justify-content: space-between;
    width: 30%;
    align-items: center;
    background-color: var(--persian-orange);
    padding: 0.25rem;
    border-radius: 180px;
}

.tab {
    position: relative;
    border-radius: 50px;
    color: var(--white);
    cursor: pointer;
    transition: color 0.5s ease;
}

.tab .marker {
    position: absolute;
    height: 1rem;
    width: 1rem;
    left: 50%;
    bottom: -3rem;
    transform: translate(-50%, -50%);
    width: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 1rem solid var(--persian-orange);
    pointer-events: none;
    display: none;
}

.tab .marker.active {
    display: block;
}

.tab:hover {
    color: var(--peach);
}

.tab.active {
    background-color: var(--peach);
}

.tab.active:hover {
    color: var(--white);
}

.tab-icon {
    font-size: 1.125rem;
    padding: 0.75rem;
}

.tab-content {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    white-space: pre-line;
}

.tab-pane {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 60%;
    margin: auto;
    background-color: var(--persian-orange);
    padding: 2rem;
    border-radius: 10px;
    min-height: 15rem;
}

.tab-pane h2 {
    text-transform: uppercase;
    padding-bottom: 1rem;
    color: var(--white);
}

.tab-pane p {
    color: var(--white);
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.125rem;
}

.tab-pane.show {
    display: block;
}

/*#region Media queries*/

/* Notebook */
@media (max-width: 1550px) {
    .tab-pane {
        min-height: 17rem;
    }
}

/* Tablet */
@media (max-width: 991px) {
    .tabs {
        width: 60%;
    }

    .tab-pane {
        width: 80%;
        min-height: 18rem;
    }
}

/* Mobile */
@media (max-width: 767px) {
    .tabs {
        width: 95%;
    }

    .tab-pane {
        width: 95%;
        min-height: 21rem;
    }

    .tab-pane h2 {
        font-size: 0.75rem;
    }
}

/*#endregion*/
</style>
  