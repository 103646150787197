<template>
  <footer>
    <div class="container">
      <div class="footer-content">
        <div class="impressum">
          <div class="impressum-entry">
            <h3>Anschrift</h3>
            <p>
              scientelligence<br>
              c/o incs Intelligent Corporate Solutions GmbH<br>
              Edmund-Rumpler-Straße 6A<br>
              51149 Köln<br>
            </p>
          </div>
          <div class="impressum-entry">
            <h3>Telefon</h3>
            <table>
              <tr>
                <td>Markus P.</td>
                <td>+49 170 1057478</td>
              </tr>
              <tr>
                <td>Maren C.</td>
                <td>+49 151 67068225</td>
              </tr>
            </table>
          </div>
          <div class="impressum-entry">
            <h3>Kontakt</h3>
            <div><font-awesome-icon :icon="['fas', 'square-envelope']" class="icon" /><a href="mailto:info@scientelligence.org">info@scientelligence.org</a></div>
            <div><font-awesome-icon :icon="['fab', 'linkedin']" class="icon" /><a href="https://www.linkedin.com/company/scientelligence/">LinkedIn</a></div>
          </div>
        </div>
          <div class="note">
            <p>Hinweis: Unser Unternehmen befindet sich derzeit in Gründung.</p>
          </div>
      </div>
    </div>
  </footer>
</template>
  
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);

export default {
  name: 'FooterComponent',
  components: {
    FontAwesomeIcon
  }
};
</script>
  
<style scoped>
.container {
 position: relative;
 background-color: var(--white);
}

.footer-content {
  display: flex;
  flex-direction: column;
  border-top: 0.25rem solid;
  border-top-color: var(--persian-orange);
}

.icon {
  color: var(--davys-gray);
}

.impressum {
  display: flex;
  justify-content: space-between;
  padding: 1rem 8rem;
}

.impressum-entry {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.impressum-entry h3 {
  color: var(--davys-gray);
  font-size: 0.6125rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.impressum-entry div {
display: flex;
align-items: center;
gap: 0.25rem;
}

.impressum-entry p,
.impressum-entry a,
.impressum-entry td {
  color: var(--davys-gray);
  text-decoration: none;
  font-size: 0.5rem;
}

.impressum-entry tr td:first-child {
  padding-right: 0.25rem;
}

.impressum-entry tr td:last-child {
  color: var(--persian-orange);
  font-weight: 500
}

.impressum-entry table {
  border-collapse: collapse;
}

.note {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 0;
  border-top: 1px solid var(--davys-gray);
}

.note p {
  font-size: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  color: var(--davys-gray);
}

/*#region Media queries*/

/* Notebook */
@media (max-width: 1550px) {}

/* Tablet */
@media (max-width: 991px) {
  .impressum {
    padding: 1rem 3rem;
  }
}

/* Mobile */
@media (max-width: 767px) {
  .impressum {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .note p {
    text-align: center;
    font-size: 0.375rem;
  }

  table {
    margin-right: auto;
  }
}

/*#endregion*/
</style>