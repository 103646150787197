<template>
    <div class="employee-card" data-aos="fade-up" :data-aos-duration="employee.fade" @click="hovered = !hovered"
        @mouseenter="hovered = true" @touchstart="hovered = hovered" @mouseleave="hovered = false"
        @touchend="hovered = false">
        <img :src="employee.imageUrl" alt="Employee" width="2520px">
        <div class="overlay" v-if="true">
            <div class="text">
                <h3>{{ employee.name }}</h3>
                <p class="role">{{ employee.role }}</p>
                <p>{{ employee.description }}</p>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: ['employee'],
    data() {
        return {
            hovered: false
        };
    }
};
</script>
  
<style scoped>
.employee-card {
    position: relative;
    cursor: pointer;
    user-select: none;
    -webkit-box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.5);
}

.employee-card img {
    position: relative;
    filter: grayscale(100%) contrast(1.2);
    width: 100%;
    height: auto;
    vertical-align: middle;
}

.overlay {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    transition: opacity 0.5s ease;
    max-height: fit-content;
}

.employee-card .overlay:hover,
.employee-card .overlay:active {
    opacity: 1;
}

.employee-card .text {
    flex-direction: column;
    gap: 3em;
}

.employee-card .text .role {
    color: var(--persian-orange);
    font-weight: 600;
}

.employee-card .text h3 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.75rem;
    padding: 1rem 0 0 0;
    font-weight: bold;
    color: var(--davys-gray)
}

.employee-card .text p {
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: var(--davys-gray);
    font-size: 0.5rem;
}

/*#region Media queries*/

/* Notebook */
@media (max-width: 1550px) {}

/* Tablet */
@media (max-width: 991px) {
    .employee-card {
        width: 40%;
    }
}

/* Mobile */
@media (max-width: 767px) {
    .employee-card {
        width: 80%;
    }

    .employee-card .text .role {
        color: var(--persian-orange);
        font-weight: 600;
    }

    .employee-card .text h3 {
        font-size: 1rem;
    }

    .employee-card .text p {
        font-size: 0.75rem;
    }
}

/*#endregion*/
</style>
  