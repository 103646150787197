<template>
  <Header />
  <Main />
  <Footer />
  <ScrollToTopButton />
</template>

<script>

import Header from './components/Header.vue'
import Main from './components/Main.vue'
import Footer from './components/Footer.vue'
import ScrollToTopButton from './components/ScrollToTopButton.vue'

export default {
  name: 'App',
  components: {
    Header,
    Main,
    ScrollToTopButton,
    Footer
  }
}
</script>

<style scoped>
@import '@/css/styles.css';
</style>
