<template>
    <a v-if="showButton" @click="scrollToTop" href="#top" class="scroll-to-top">
        <font-awesome-icon :icon="['fas', 'chevron-up']" class="icon" />
    </a>
</template>
  
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
    data() {
        return {
            showButton: false
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        this.handleScroll();
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.showButton = window.scrollY > 100; 
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    },
    components: {
        FontAwesomeIcon
    }
};
</script>
  
<style scoped>
.scroll-to-top {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 99;
    background-color: var(--white);
    color: var(--white);
    border: 3px solid var(--davys-gray);
    border-radius: 5px;
    padding: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.5s ease;
}

.icon {
    color: var(--persian-orange);
    transition: color 0.5s ease;
    font-size: 1.1rem;
}

/*#region Media queries*/

/* Notebook */
@media (max-width: 1550px) {}

/* Tablet */
@media (max-width: 991px) {}

/* Mobile */
@media (max-width: 767px) {
    .icon {
        font-size: 1.5rem;
    }
}

/*#endregion*/
</style>
  