import { createApp } from 'vue'
import App from './App.vue'

import AOS from 'aos'
import 'aos/dist/aos.css'

import VueParticles from 'vue-particles';

const app = createApp(App);

app.use(AOS.init());
app.use(VueParticles);

app.mount('#app');